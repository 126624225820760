.skeleton {
    display: flex;
    height: 61px;
    justify-content: space-between;
    gap: 0.1rem;
    margin-bottom: 7rem;
    margin-left: 0.5rem;
    margin-right: 1rem;
}

.skeleton__card {
    height: 185px;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    margin-bottom: 7rem;
}

.skeleton__card__one {
    border-radius: 15px !important;
    width: 75%;
    height: 100%;
    box-shadow: 0 0.1rem 1rem rgb(0 0 0 / 25%);
    padding: 1.6rem 2.3rem 2.2rem 3.5rem;
}

.skeleton__card__title {
    position: relative;
    height: 1.2rem;
    width: 40%;
    animation: skeleton-loading .5s linear infinite alternate;
    border-radius: 5px;
}

.skeleton__card__two {
    width: 25%;
    height: 100%;
    box-shadow: 0 0.1rem 1rem rgb(0 0 0 / 25%);
    border-top-left-radius: 15px !important;
    border-bottom-left-radius: 15px !important;
    padding: 1.6rem 0rem 2.2rem 4rem;
}

.skeleton__card__title__two {
    position: relative;
    height: 1.2rem;
    border-radius: 5px;
    width: 100%;
    animation: skeleton-loading .5s linear infinite alternate;
}

.skeleton__text {
    width: 80%;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: space-around;
}

.skeleton__title,
.skeleton__title__2 {
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 30%;
    animation: skeleton-loading .5s linear infinite alternate;
}

.skeleton__title__2 {
    width: 70%;
}

.skeleton__desc {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 25%;
    gap: 4rem;
}

.skeleton__author {
    width: 45%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 100%;
    animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton__dot {
    width: 5%;
    height: 25%;
    border-radius: 50%;
    animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton__date {
    width: 45%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 100%;
    animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton__image {
    width: 20%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    animation: skeleton-loading .5s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: #E4E6EB;
    }
    100% {
        background-color: hsl(0deg 19% 96%);
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.modal-main {
    position: fixed;
    background: white;
    width: 75%;
    height: 40%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.open-modal {
    display: block;
}

.close-modal {
    display: none;
}

.share__dropdown {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 2rem;
}

.makeStyles-copyContainer-5,
.makeStyles-copyContainer-13 {
    display: none !important;
}

.makeStyles-container-1 {
    background: #fff !important;
}

.makeStyles-iconContainer-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.share_file {
    display: none;
    position: fixed;
    top: 44px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255 255 255 / 92%);
}

.show__share__link {
    display: block !important;
}

.makeStyles-container-1 {
    padding: 0 !important;
}